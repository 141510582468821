<template>
  <v-main class="clinician-edit-page">
    <div v-if="clinician" class="app-container pt-10 pb-2">
      <section
        class="clinician-edit-page__tool-bar d-flex flex-column justify-space-between align-start mb-5"
      >
        <v-row v-if="!isClinician" no-gutters>
          <v-col class="md-12">
            <Breadcrumbs :items="breadCrumbsItems" />
          </v-col>
        </v-row>

        <v-row no-gutters class="flex-row justify-start align-center mt-4">
          <v-col :cols="isMobile ? '11' : '9'" class="d-flex">
            <div
              class="clinician-edit-page__tool-bar__user-info d-flex align-center justify-center"
            >
              <v-avatar color="grey lighten-2" size="80">
                <v-img
                  v-if="clinician"
                  :src="clinicianImage"
                  :alt="clinician.name"
                />
              </v-avatar>
              <CliniciansNameBadge
                :clinician-status="clinician.category || ''"
              />
            </div>
            <div class="ml-5 d-flex flex-column justify-start align-start">
              <p class="text-subtitle-1 text-medium-emphasis text--secondary">
                {{ clinicianPosition }}
              </p>
              <h1 class="text-h5 font-weight-medium">
                {{ clinician.name }}
              </h1>
              <v-tooltip :disabled="!$vuetify.breakpoint.sm" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    :style="
                      $vuetify.breakpoint.mobile ? 'max-width: 170px' : ''
                    "
                    class="text-subtitle-1 text-medium-emphasis text--secondary text-truncate"
                    v-on="on"
                  >
                    Email:
                    <span class="black--text">
                      {{ get(clinician, "email", "-") }}
                    </span>
                    <br />
                    Manager:
                    <span class="black--text">
                      {{ get(clinician?.manager, "name", "-") }}
                    </span>
                  </p>
                </template>
              </v-tooltip>
            </div>
          </v-col>

          <v-col
            v-if="isAdmin || isManager"
            :cols="isMobile ? '1' : '3'"
            class="d-flex justify-end align-center"
          >
            <v-btn
              v-if="!isMobile"
              color="primary"
              rounded
              large
              variant="elevated"
              @click="isModalVisible = true"
            >
              <v-icon class="mr-2"> mdi-pencil </v-icon>
              <span>CHANGE</span>
            </v-btn>
            <v-icon v-else color="gray" @click="isModalVisible = true">
              mdi-pencil
            </v-icon>
          </v-col>
        </v-row>
      </section>
      <div class="mt-1 mb-3">
        Services:
        <v-chip
          v-for="{ name } in assignedClinicalServices"
          :key="name"
          class="mr-2 mb-2"
        >
          {{ name }}
        </v-chip>
      </div>

      <section
        class="clinician-edit-page__details d-flex justify-space-between mt-10"
      >
        <div class="clinician-edit-page__details--graphics mb-4">
          <v-card
            v-if="categoryChangelogs"
            class="clinician-edit-page__details--graphics-dynamics mb-5 pa-3"
          >
            <v-card-title class="pa-0"> Clinician Dynamics </v-card-title>
            <ClinicianDynamicsTimeline :statuses-logs="categoryChangelogs" />
          </v-card>

          <v-card
            v-if="casesStatistic"
            class="clinician-edit-page__details--graphics-statistics pa-3 mb-4"
          >
            <v-card-title class="pa-0"> Statistic </v-card-title>

            <ClinicianStatisticDiagram
              :statistic="casesStatistic"
              class="ml-auto mr-auto"
            />
          </v-card>

          <StatisticItem
            v-if="clinicianScore"
            :value="clinicianScore"
            :max-width="'none'"
            is-percent
            text="Average Score"
            class="mb-4"
          />

          <v-card v-if="auditDynamic.length" class="pa-3">
            <v-card-title class="pa-0">Dynamics</v-card-title>
            <AuditLineChart :chart-data="auditDynamic" />
          </v-card>
        </div>

        <v-card class="clinician-edit-page__details--cases-table pa-4">
          <v-card-title class="pa-0 mb-3">Cases</v-card-title>

          <v-data-table
            :headers="tableHeaders"
            :items="cases"
            :mobile-breakpoint="0"
            :options.sync="options"
            :server-items-length="totalCases"
            :hide-default-footer="isMobile"
            :footer-props="tableFooterOptions"
            multi-sort
            @update:options="handleTableUpdate"
          >
            <template v-slot:item="{ item }">
              <tr
                :class="
                  getScoreRowColor(get(item, 'audit._id.caseGrade.score', null))
                "
                @click="handleTableRowClick(item)"
              >
                <td>{{ get(item, "case.caseReference", "-") }}</td>
                <td>
                  {{ dateToLocalString(get(item, "case.caseDate"), false) }}
                </td>
                <td>
                  <CaseStatusChip
                    :status="get(item, 'audit.status', null)"
                    style="max-width: 153px"
                  />
                </td>
                <td>{{ getAuditScore(item) }}</td>
                <td>
                  {{ dateToLocalString(get(item, "case.createdAt"), false) }}
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-pagination
            v-if="isMobile"
            :value="options.page"
            :length="paginationLength"
            class="mt-3"
            @input="handlePaginationChange"
          />
        </v-card>
      </section>

      <ClinicianEditModal
        :dialog="isModalVisible"
        :clinician="clinician"
        :managers="managers"
        :is-modal-visible="isModalVisible"
        :manager-options="managerOptions"
        :positions="clinicianPositions"
        :positions-options="clinicianPositionsOptions"
        :is-mobile="isMobile"
        :loading="editClinicianLoading"
        @cancel="handleEditModalToggle"
        @submit="handleClinicianUpdate"
      />
    </div>
  </v-main>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { get } from "@/helpers/get";
import { getAuditScore, getScoreRowColor } from "@/helpers/audit";
import CliniciansNameBadge from "@/components/common/CliniciansNameBadge.vue";
import ClinicianStatisticDiagram from "@/components/clinicians/ClinicianStatisticDiagram.vue";
import ClinicianDynamicsTimeline from "@/components/clinicians/ClinicianDynamicsTimeline.vue";
import ClinicianEditModal from "@/components/clinicians/ClinicianEditModal.vue";
import DefaultAvatar from "@/assets/unknown-user.png";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import CaseStatusChip from "@/components/common/CaseStatusChip.vue";
import StatisticItem from "@/components/common/StatisticItem.vue";
import AuditLineChart from "@/components/common/AuditLineChart.vue";
import {
  getClinicianCases,
  getClinicianStatistic,
  getManagers,
  getClinicianById,
  getUserDynamic,
  updateUserById,
} from "@/services/users";
import dateToLocalString from "@/helpers/dateToLocalString";
import {
  CasesStatusesIcons,
  PagesBreadCrumbTitles,
  RoutesMapping,
  UserRoles,
} from "@/misc/constants";
import { tableFooterOptions, tableHeaders } from "./constants";
import { getClinicalServices } from "@/services/clinicalServices";
import { getClinicianPositions } from "@/services/accountConfiguration";

export default {
  name: "ClinicianEditPage",

  components: {
    CliniciansNameBadge,
    ClinicianDynamicsTimeline,
    ClinicianStatisticDiagram,
    ClinicianEditModal,
    Breadcrumbs,
    CaseStatusChip,
    StatisticItem,
    AuditLineChart,
  },

  data() {
    const breadCrumbsItems = [
      {
        title: PagesBreadCrumbTitles.clinicians,
        disabled: false,
        to: RoutesMapping[PagesBreadCrumbTitles.clinicians],
      },
      {
        title: "Clinician Profile",
        disabled: true,
      },
    ];

    return {
      UserRoles,
      breadCrumbsItems,
      options: {},
      tableHeaders,
      loading: false,
      clinician: null,
      cases: [],
      totalCases: null,
      isModalVisible: false,
      isEditFormVisible: false,
      statistic: null,
      tableFooterOptions,
      CasesStatusesIcons,
      filters: {
        _id: null,
      },
      editClinicianLoading: false,
      dynamic: null,
      managers: [],
      managerOptions: [],
      clinicianPositions: [],
      clinicianPositionsOptions: [],
      assignedClinicalServices: [],
    };
  },

  computed: {
    ...mapState(useUserStore, ["user", "isClinician", "isAdmin", "isManager"]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    clinicianPosition() {
      let position;
      if (this.clinicianPositions) {
        position = this.clinicianPositions.find(
          (item) => item?._id === this.clinician?.position
        );
      }
      return position !== null ? position?.name : null;
    },
    clinicianImage() {
      if (!this.clinician) return;
      return this.clinician?.imageUrl || DefaultAvatar;
    },
    categoryChangelogs() {
      if (
        !this.clinician?.categoryLogs ||
        !this.clinician?.categoryLogs?.length
      )
        return null;
      return this.clinician.categoryLogs;
    },
    clinicianStatistic() {
      if (
        !this.clinician.safe ||
        !this.clinician.unsafe ||
        !this.clinician.acceptable
      )
        return null;
      return {
        unsafe: this.clinician.unsafe || 0,
        safe: this.clinician.safe || 0,
        acceptable: this.clinician.acceptable || 0,
      };
    },
    paginationLength() {
      if (!this.options || !this.options.itemsPerPage) return 0;
      return Math.ceil(this.totalCases / this.options.itemsPerPage);
    },
    casesStatistic() {
      if (!this.statistic?.statistic) return null;
      return this.statistic?.statistic;
    },
    clinicianScore() {
      if (!this.statistic?.score) return null;
      return this.statistic.score;
    },
    auditDynamic() {
      return this.dynamic?.data || [];
    },
  },

  async mounted() {
    await Promise.all([
      this.getUser(),
      this.getClinicianStatistic(),
      this.getAllManagers(),
      this.fetchClinicianPositions(),
    ]);
    await this.fetchAssignedClinicalServices();
  },

  methods: {
    get,
    dateToLocalString,
    getScoreRowColor,
    getAuditScore,
    async fetchClinicianPositions() {
      const { data } = await getClinicianPositions({});
      this.clinicianPositions = data;
      this.clinicianPositionsOptions = this.clinicianPositions.map(
        ({ name }) => name
      );
    },
    async getAllManagers() {
      try {
        const managers = await getManagers({ skipPaginate: true });
        this.managers = managers.map(({ _id: id, name }) => ({ id, name }));
        this.managerOptions = managers.map(({ name }) => name);
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      }
    },
    async getUser() {
      try {
        const { id } = this.$route.params;
        this.clinician = await getClinicianById(id);
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      }
    },
    async getClinicianStatistic() {
      try {
        const { id } = this.$route.params;
        [this.statistic, this.dynamic] = await Promise.all([
          getClinicianStatistic(id),
          getUserDynamic(id),
        ]);
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      }
    },
    async fetchCases() {
      try {
        if (!this.clinician) return;
        this.loading = true;
        const query = this.buildQuery();
        const { data, total } = await getClinicianCases(
          this.clinician._id,
          query
        );
        this.totalCases = total;
        this.cases = data;
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.loading = false;
      }
    },
    buildQuery() {
      const queryParams = {};
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      queryParams.page = page || 1;
      queryParams.pageSize = itemsPerPage || 10;

      if (sortBy && sortDesc && sortBy.length && sortDesc.length) {
        const sort = sortBy.reduce((acc, el, ind) => {
          const sortBy = `${el}:${sortDesc[ind] ? -1 : 1}`;
          acc += ind === 0 ? sortBy : `,${sortBy}`;
          return acc;
        }, "");
        queryParams.sort = sort;
      }

      return queryParams;
    },
    async fetchAssignedClinicalServices() {
      const clinicalServices =
        this.clinician.clinicalServices?.filter(Boolean) || [];
      if (!clinicalServices?.length) return;

      const query = { filter : { _id: { $in: clinicalServices }}};
      try {
        this.assignedClinicalServices = await getClinicalServices(query);
        return this.assignedClinicalServices;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Failed to fetch clinical services",
        });
      }
    },
    async handleTableUpdate() {
      await this.fetchCases();
    },
    handleTableRowClick(item) {
      const navigationQuery = {
        prev: PagesBreadCrumbTitles.clinicians,
        path: `${this.clinician.name}:${this.clinician._id}`,
      };
      const {
        case: { _id },
        type,
      } = item;
      const auditId = get(item, "audit._id._id", null);
      this.$router.push({
        path: `/cases/${_id}`,
        query: {
          ...navigationQuery,
          ...(auditId ? { caseType: type, auditId } : {}),
        },
      });
    },
    handleEditModalToggle(value) {
      this.isModalVisible = value;
    },
    handlePaginationChange(value) {
      this.options.page = value;
      return this.handleTableUpdate();
    },
    async handleClinicianUpdate(payload) {
      this.editClinicianLoading = true;
      try {
        const { id } = this.$route.params;
        await updateUserById(id, payload);
        await this.getUser();
        await this.fetchAssignedClinicalServices();
        this.$notify({
          type: "success",
          title: "Update user",
          text: "User data is successfully updated",
        });
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Update user",
          text: e?.response?.data?.message || JSON.stringify(e),
        });
      } finally {
        this.editClinicianLoading = false;
        this.handleEditModalToggle(false);
      }
    },
  },
};
</script>

<style lang="scss">
.clinician-edit-page {
  &__tool-bar {
    .row {
      width: 100%;
    }

    &__user-info {
      position: relative;
      width: 80px;
      height: 80px;

      .clinicians-name-badge {
        position: absolute;
        border: 2px solid var(--v-on-primary-base);
        width: 18px;
        height: 18px;
        bottom: 5px;
        right: 5px;
      }
    }
  }

  &__details {
    flex-direction: row-reverse;

    &--graphics {
      width: 33%;
    }

    &--cases-table {
      height: fit-content;
      width: 65%;
    }

    @media (max-width: 992px) {
      flex-direction: column;

      &--graphics,
      &--cases-table {
        width: 100%;
      }
    }
  }

  .v-pagination {
    &__navigation {
      box-shadow: none;
    }

    &__item {
      box-shadow: none;
    }
  }
}
</style>
